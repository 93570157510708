// Region.js
import "./Region.css";
import MusicianContext from "./MusicianContext";
import { useContext } from "react";

const icons_Idan = {
  group1: {
    0: "guitar_pick",
    1: "guitar_mute",
    2: "guitar_fingers",
    3: "guitar_strum",

  },
  group2: {
    0: "amp_clean",
    1: "space_3",
    2: "amp_with_head",
    3: "amp_big",
    4: "square_distorted",
  },
};

const icons_lior = {
  group1: {
    0: "Jazz bass",
    1: "Fretless",
    2: "Precision bass",
  },
  group2: {
    0: "M0003_g2_1_fingers",
    1: "M0003_g2_2_pick",
    2: "M0003_g2_3_slap",
    3: "M0003_g2_4_mute",
  },
  group3: {
    0: "M0003_g3_1_di",
    1: "M0003_g3_2_drive",
    2: "M0003_g3_3_octaver",
  },
};

function Bar({ progress }) {
  const left = progress[0] * 100;
  const width = (progress[1] - progress[0]) * 100;
  return (
    <div className="bar">
      <div
        className="bar"
        style={{
          width: width + "%",
          marginLeft: left + "%",
          background: "#fff",
        }}
      ></div>
    </div>
  );
}

function Knob({ kind, progress }) {
  return (
    <div className="knob">
      <span>{kind + ": "}</span>
      <span>
        <Bar progress={progress} />
      </span>
    </div>
  );
}

function SpecialEffectsIcon({ state }) {
  const { musicianName } = useContext(MusicianContext) || {};
  const effectiveMusicianName = musicianName || "Idan Shneor"; // Fallback if somehow still undefined
  console.log("SpecialEffectsIcon - musicianName:", effectiveMusicianName);
  const customIcon = effectiveMusicianName === "Idan Shneor" ? "stereo_double" : "Stereo Double ";
  const iconPath = `./${customIcon}.svg`;

  if (!state) return <img className="filter-icon-on" src={iconPath} alt="Special Effect" />;

  if (state[0] === false && state[1] === true) {
    return <img className="filter-icon-on" src={iconPath} alt="Special Effect" />;
  } else if (state[0] === true && state[1] === false) {
    return <img className="filter-icon-off" src={iconPath} alt="Special Effect" />;
  } else if (state[0] === true && state[1] === true) {
    return <img className="filter-icon-on-off" src={iconPath} alt="Special Effect" />;
  } else {
    return <img className="filter-icon-on" src={iconPath} alt="Special Effect" />;
  }
}

function GroupIcon({ isOn, kind, icon }) {
  const state = isOn ? "icon-on" : "icon-off";
  console.log("GroupIcon - isOn:", isOn, "kind:", kind, "icon:", icon);
  return (
    <img
      style={{ objectFit: "contain", imageRendering: "-webkit-optimize-contrast" }}
      className={state}
      src={`./${icon}.svg`}
      alt={`Group Icon ${kind}`}
    />
  );
}

function IconsBackground({ instrument }) {
  console.log("IconsBackground - instrument:", instrument);
  switch (instrument) {
    case "drummist":
      return <img className="icons-background" src="./icons_bg2.png" alt="Background" />;
    default:
      return <img className="icons-background" src="./icons_bg1.png" alt="Background" />;
  }
}

function RightArrow() {
  return <img className="right-arrow" src="./right_arrow.png" alt="Right Arrow" />;
}
function LeftArrow() {
  return <img className="left-arrow" src="./left_arrow.png" alt="Left Arrow" />;
}

function ShortRegion({ region, arrow, onRegionClick }) {
  console.log("ShortRegion - region:", region, "arrow:", arrow);
  const rightArrow = arrow === "r" || arrow === "rl" || arrow === "lr" ? <RightArrow /> : null;
  const leftArrow = arrow === "l" || arrow === "rl" || arrow === "lr" ? <LeftArrow /> : null;

  return (
    <div className={"box " + region.type} onClick={onRegionClick}>
      {leftArrow}
      <b>{region.type[0].toUpperCase() + region.type.slice(1)}</b>
      <p />
      <h1 style={{ fontSize: "35px", fontWeight: "500" }}>!</h1>
      {rightArrow}
    </div>
  );
}

function BreakRegion({ arrow }) {
  console.log("BreakRegion - arrow:", arrow);
  const rightArrow = arrow === "r" || arrow === "rl" || arrow === "lr" ? <RightArrow /> : null;
  const leftArrow = arrow === "l" || arrow === "rl" || arrow === "lr" ? <LeftArrow /> : null;
  return (
    <div className={"box break"}>
      {leftArrow}
      <b>Break</b>
      {rightArrow}
    </div>
  );
}

function ExpandedRegion({ region }) {
  const { musicianName } = useContext(MusicianContext) || {};
  const effectiveMusicianName = musicianName || "Idan Shneor"; 
  console.log("ExpandedRegion - musicianName:", effectiveMusicianName, "region:", region);
  const icons = effectiveMusicianName === "Idan Shneor" ? icons_Idan : icons_lior;

  const g1Icons = (region.group1 || []).map(
    (isOn, index) =>
      icons.group1[index] && <GroupIcon key={`g1-${index}`} isOn={isOn} kind={index} icon={icons.group1[index]} />
  );
  const g2Icons = (region.group2 || []).map(
    (isOn, index) =>
      icons.group2[index] && <GroupIcon key={`g2-${index}`} isOn={isOn} kind={index} icon={icons.group2[index]} />
  );
  const g3Icons = (region.group3 || []).map(
    (isOn, index) =>
      icons.group3 && icons.group3[index] && <GroupIcon key={`g3-${index}`} isOn={isOn} kind={index} icon={icons.group3[index]} />
  );

  return (
    <div className="expanded-container">
      <p>{region.end - region.start + " Bars"}</p>
      <div className="expanded-box">
        <p>{"Bar " + (region.start + 1)}</p>
        <div
          className={"box " + region.type}
          style={{ width: `${(region.end - region.start + 1) * 25}vw` }}
        >
          <div className="knob-container">
            <b>{region.type[0].toUpperCase() + region.type.slice(1)}</b>
            <Knob kind="D" progress={region.knobs.dynamics} />
            <Knob kind="C" progress={region.knobs.complexity} />
            <Knob kind="F" progress={region.knobs.fills} />
          </div>
          <div className="icon-container">
            <IconsBackground />
            <SpecialEffectsIcon state={region.specialEffects} />
            {g1Icons}
            <br />
            {g2Icons}
            <br />
            {g3Icons}
          </div>
        </div>
        <p>{"Bar " + (region.end + 1)}</p>
      </div>
    </div>
  );
}

function Region({ region, arrow, isShort, isExpanded, onRegionClick }) {
  const { musicianName } = useContext(MusicianContext) || {};
  const effectiveMusicianName = musicianName || "Idan Shneor";
  console.log("Region - musicianName:", effectiveMusicianName, "region:", region);

  const icons = effectiveMusicianName === "Idan Shneor" ? icons_Idan : icons_lior;

  if (region.type === "break") {
    return <BreakRegion arrow={arrow} />;
  }

  if (isShort === true) {
    return <ShortRegion region={region} arrow={arrow} onRegionClick={onRegionClick} />;
  }

  if (isExpanded === true) {
    return (
      <ExpandedRegion
        region={{
          ...region,
          group1: region.group1 || [],
          group2: region.group2 || [],
          group3: region.group3 || [],
        }}
      />
    );
  }

  const rightArrow = arrow === "r" || arrow === "rl" || arrow === "lr" ? <RightArrow /> : null;
  const leftArrow = arrow === "l" || arrow === "rl" || arrow === "lr" ? <LeftArrow /> : null;
  const g1Icons = (region.group1 || []).map(
    (isOn, index) =>
      icons.group1[index] && <GroupIcon key={`g1-${index}`} isOn={isOn} kind={index} icon={icons.group1[index]} />
  );
  const g2Icons = (region.group2 || []).map(
    (isOn, index) =>
      icons.group2[index] && <GroupIcon key={`g2-${index}`} isOn={isOn} kind={index} icon={icons.group2[index]} />
  );
  const g3Icons = (region.group3 || []).map(
    (isOn, index) =>
      icons.group3 && icons.group3[index] && <GroupIcon key={`g3-${index}`} isOn={isOn} kind={index} icon={icons.group3[index]} />
  );

  return (
    <div className={"box " + region.type}>
      {leftArrow}
      <div className="knob-container">
        <b>{region.type[0].toUpperCase() + region.type.slice(1)}</b>
        <Knob kind="D" progress={region.knobs.dynamics} />
        <Knob kind="C" progress={region.knobs.complexity} />
        <Knob kind="F" progress={region.knobs.fills} />
      </div>

      <div className="icon-container">
        <IconsBackground />
        <SpecialEffectsIcon state={region.specialEffects} />
        {g1Icons}
        <br />
        {g2Icons}
        <br />
        {g3Icons}
      </div>
      {rightArrow}
    </div>
  );
}

export default Region;
