// MusicianContext.js
import React, { createContext, useState, useEffect } from 'react';

const MusicianContext = createContext();

export const MusicianProvider = ({ children, defaultMusicianName = "Idan Shneor" }) => {
  const [musicianName, setMusicianName] = useState(defaultMusicianName);

  useEffect(() => {
    // If defaultMusicianName changes, update musicianName accordingly
    setMusicianName(defaultMusicianName);
  }, [defaultMusicianName]);

  return (
    <MusicianContext.Provider value={{ musicianName, setMusicianName }}>
      {children}
    </MusicianContext.Provider>
  );
};

export default MusicianContext;
