// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './fonts/GenerationSans.css';
import Main from './Main.js';
import { MusicianProvider } from './MusicianContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <MusicianProvider defaultMusicianName="Idan Shneor">
      <Main />
    </MusicianProvider>
  </React.StrictMode>
);

reportWebVitals();
